<template>
  <div class="pricing-strategy-container">
    <!-- 媒体出价策略 -->
    <a-form-model-item :prop="`${propText}mediaSettleStrategy`">
      <m-tips
        iconPosition="left"
        slot="label"
        :content="
          `媒体出价：广告填充时，给媒体返回的广告价格。` +
            `</br>` +
            `策略：` +
            `</br>` +
            `(1)默认：以广告响应的价格直接返回。` +
            `</br>` +
            `(2)百分比浮动：对广告响应的价格按百分比向下浮动后返回。` +
            `</br>` +
            `(3)平台自定义：以平台设置的价格返回。`
        "
        :title="`${isDefault ? '默认' : ''}媒体出价策略`"
        :width="250"
      ></m-tips>
      <el-radio-group v-model="formquery.mediaSettleStrategy" size="medium" @change="changeSet">
        <el-radio label="0" border>默认</el-radio>
        <el-radio label="1" border>百分比浮动</el-radio>
        <el-radio label="2" border>平台自定义</el-radio>
        <el-radio label="3" border>PMP</el-radio>
        <el-radio label="4" border>CPC</el-radio>
        <el-radio label="5" border>CPA</el-radio>
      </el-radio-group>
    </a-form-model-item>
    <!-- 浮动比例 -->
    <a-form-model-item
      :prop="`${propText}mediaSettleValue`"
      v-if="+formquery.mediaSettleStrategy === 1"
      :rules="[{ required: true, validator: validatorFloatingRate, trigger: 'blur' }]"
    >
      <m-tips
        slot="label"
        :content="`浮动百分比不限范围，正数表示向下浮动以增加利润，负数则向上浮动可能导致亏损。请依据业务需求谨慎设定。`"
        :title="'价格浮动'"
        :width="250"
      ></m-tips>
      <a-input type="number" :min="0" :max="100" v-model.number.trim="query.mediaSettleValue" placeholder="请输入价格浮动">
        <span class="percent-text" slot="suffix">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 结算底价 -->
    <a-form-model-item
      label="结算底价"
      :prop="`${propText}mediaSettleValue`"
      :rules="[{ required: true, validator: validatorSettleBidfloor, trigger: 'blur' }]"
      v-if="+formquery.mediaSettleStrategy === 2 || +formquery.mediaSettleStrategy === 3"
    >
      <a-input type="number" :min="0" v-model.number.trim="query.mediaSettleValue" placeholder="请输入结算底价">
        <span class="percent-text" slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- CPC价格 -->
    <a-form-model-item
      label="CPC价格"
      :prop="`${propText}mediaSettlePriceValue`"
      :rules="[{ required: true, validator: validatorCPC, trigger: 'blur' }]"
      v-if="+formquery.mediaSettleStrategy === 4"
    >
      <a-input type="number" :min="0" v-model.number.trim="query.mediaSettlePriceValue" placeholder="请输入CPC价格">
        <span class="percent-text" slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- CPA价格 -->
    <a-form-model-item
      label="CPA价格"
      :prop="`${propText}mediaSettlePriceValue`"
      :rules="[{ required: true, validator: validatorCPA, trigger: 'blur' }]"
      v-if="+formquery.mediaSettleStrategy === 5"
    >
      <a-input type="number" :min="0" v-model.number.trim="query.mediaSettlePriceValue" placeholder="请输入CPA价格">
        <span class="percent-text" slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- 最大CPM -->
    <a-form-model-item
      label="最大CPM"
      :prop="`${propText}mediaSettleValue`"
      :rules="[{ required: true, validator: validatorMaxCPM, trigger: 'blur' }]"
      v-if="+formquery.mediaSettleStrategy === 4 || +formquery.mediaSettleStrategy === 5"
    >
      <a-input type="number" :min="0" v-model.number.trim="query.mediaSettleValue" placeholder="请输入最大CPM">
        <span class="percent-text" slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- ctr -->
    <a-form-model-item
      label="ctr"
      :prop="`${propText}mediaSettleCtrValue`"
      :rules="[{ required: true, message: '请输入ctr', trigger: 'blur' }]"
      v-if="+formquery.mediaSettleStrategy === 4 || +formquery.mediaSettleStrategy === 5"
    >
      <a-input v-model.trim="query.mediaSettleCtrValue" placeholder="请输入ctr"> </a-input>
    </a-form-model-item>
    <!-- cvr -->
    <a-form-model-item
      label="cvr"
      :prop="`${propText}mediaSettleCvrValue`"
      :rules="[{ required: true, message: '请输入cvr', trigger: 'blur' }]"
      v-if="+formquery.mediaSettleStrategy === 5"
    >
      <a-input v-model.trim="query.mediaSettleCvrValue" placeholder="请输入cvr"> </a-input>
    </a-form-model-item>

    <!-- dealid -->
    <a-form-model-item
      label="dealid"
      :prop="`${propText}dealid`"
      :rules="[{ required: true, message: '请输入dealid', trigger: 'blur' }]"
      v-if="+formquery.mediaSettleStrategy === 3"
    >
      <el-input v-model="formquery.dealid" placeholder="请输入dealid"></el-input>
    </a-form-model-item>
    <!-- 上游成交策略 -->
    <a-form-model-item :prop="`${propText}dspSettleStrategy`">
      <m-tips
        iconPosition="left"
        slot="label"
        :content="
          `上游成交：广告展示时，上报的广告成交价格。` +
            `</br>` +
            `策略：` +
            `</br>` +
            `(1)默认：按媒体上报的广告价格直接成交。` +
            `</br>` +
            `(2)百分比浮动：对媒体上报的广告价格按百分比向上浮动后成交。` +
            `</br>` +
            `(3)一价成交：以广告响应的价格直接成交。`
        "
        :title="`${isDefault ? '默认' : ''}上游成交策略`"
        :width="250"
      ></m-tips>
      <el-radio-group v-model="formquery.dspSettleStrategy" size="medium" @change="changeSet">
        <el-radio label="0" border>默认</el-radio>
        <el-radio label="1" border>百分比浮动</el-radio>
        <el-radio label="2" border>一价成交</el-radio>
        <el-radio label="3" border>平台自定义</el-radio>
        <el-radio label="4" border>一价下浮</el-radio>
        <el-radio label="5" border>指定范围</el-radio>
      </el-radio-group>
    </a-form-model-item>
    <!-- 上游成交浮动比例 -->
    <a-form-model-item
      label="上游成交浮动比例"
      v-if="+formquery.dspSettleStrategy === 1"
      :prop="`${propText}dspSettleValue`"
      :rules="[{ required: false, validator: validatorDspFloatingRate, trigger: 'blur' }]"
    >
      <a-input type="number" :min="0" :max="100" v-model.number.trim="query.dspSettleValue" placeholder="请输入价格浮动">
        <span class="percent-text" slot="suffix">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 广告成交价 -->
    <a-form-model-item
      label="广告成交价"
      v-if="+formquery.dspSettleStrategy === 3"
      :prop="`${propText}dspSettleValue`"
      :rules="[{ required: true, validator: validatorDspFloatingValue, trigger: 'blur' }]"
    >
      <a-input type="number" :min="0" v-model.number.trim="query.dspSettleValue" placeholder="请输入成交价">
        <span class="percent-text" slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- 下浮比例 -->
    <a-form-model-item
      label="下浮比例"
      v-if="+formquery.dspSettleStrategy === 4"
      :prop="`${propText}dspSettleValue`"
      :rules="[{ required: true, validator: validatorDspFloatingValue, trigger: 'blur' }]"
    >
      <a-input type="number" :min="0" v-model.number.trim="query.dspSettleValue" placeholder="请输入下浮比例">
        <span class="percent-text" slot="suffix">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 成交价范围 -->
    <a-form-model-item label="成交价范围" v-if="+formquery.dspSettleStrategy === 5">
      <div class="input-box">
        <a-input class="input-item" type="number" :min="0" v-model.number.trim="query.dspSettleMinValue" placeholder="请输入最小值">
          <span class="percent-text" slot="suffix">{{ moneyType }}</span>
        </a-input>
        <a-input class="input-item" type="number" :min="0" v-model.number.trim="query.dspSettleMaxValue" placeholder="请输入最大值">
          <span class="percent-text" slot="suffix">{{ moneyType }}</span>
        </a-input>
      </div>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      formqueryTemp: {
        mediaSettleStrategy: '0',
        mediaSettleValue: 0,
        dspSettleStrategy: '0',
        dspSettleValue: 0,
        dealid: undefined,
        mediaSettlePriceValue: undefined,
        mediaSettleCvrValue: undefined,
        mediaSettleCtrValue: undefined,
        dspSettleMinValue: undefined,
        dspSettleMaxValue: undefined
      }
    }
  },
  created () {
    this.$set(this.formquery, 'mediaSettleStrategy', this.query.mediaSettleStrategy || '0')
    this.$set(this.formquery, 'mediaSettleValue', this.query.mediaSettleValue || 0)
    this.$set(this.formquery, 'dspSettleStrategy', this.query.dspSettleStrategy || '0')
    this.$set(this.formquery, 'dspSettleValue', this.query.dspSettleValue || 0)
    this.$set(this.formquery, 'dealid', this.query.dealid || undefined)
    this.$set(this.formquery, 'mediaSettlePriceValue', this.query.mediaSettlePriceValue || undefined)
    this.$set(this.formquery, 'mediaSettleCvrValue', this.query.mediaSettleCvrValue || undefined)
    this.$set(this.formquery, 'mediaSettleCtrValue', this.query.mediaSettleCtrValue || undefined)
    this.$set(this.formquery, 'dspSettleMinValue', this.query.dspSettleMinValue || undefined)
    this.$set(this.formquery, 'dspSettleMaxValue', this.query.dspSettleMaxValue || undefined)
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('update:query', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable,
      sensitivity: (state) => state.asyncPermission.sensitivity,
      buttonSize: (state) => state.asyncPermission.buttonSize,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    propText: {
      default: '',
      type: String
    },
    isDefault: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    changeSet (data) {
      this.clearValidate()
    },
    validatorFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入浮动比例！'))
      } else {
        return callback()
      }
    },
    validatorSettleBidfloor (rule, value, callback) {
      console.log(value)
      if (!value && value !== 0) {
        return callback(new Error('请输入结算底价！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorCPC (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入CPC价格！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorCPA (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入CPA价格！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorMaxCPM (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入最大CPM价格！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorDspFloatingRate (rule, value, callback) {
      if (value < 0) {
        return callback(new Error('浮动比例必须大于等于0！'))
      } else {
        return callback()
      }
    },
    validatorDspFloatingValue (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入平台成交价！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    clearValidate () {
      this.$emit('clearValidate')
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.pricing-strategy-container .number-input-form.ant-row.ant-form-item {
  margin-bottom: 0;
}
::v-deep.pricing-strategy-container {
  .el-input__inner {
    border-radius: 4px 0 0 4px;
  }
  .priceStrategy-item {
    .ant-form-item:last-child .ant-form-item-control-wrapper {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(21, 34, 50, 0.2);
    }
  }
}
.input-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .input-item {
    width: 48%;
  }
}
</style>
